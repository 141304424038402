import { get, post, put, Delete } from "@/api/axios";

// /web/house/label/list get
export const list = params => get('/web/car/type/list', params);

// /web/house/label/add post
export const add = params => post('/web/car/type/add', params);

// /web/house/label/update put
export const update = params => put('/web/car/type/update', params);

// /web/house/label/delete/{id} delete
export const deleteNew = params => Delete('/web/car/type/delete/' + params.id, '');

// /web/house/label/view/{id} get
export const view = params => get('/web/car/type/view/' + params.id, '');
